var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.data_loaded)?_c('v-skeleton-loader',{staticClass:"mt-10",attrs:{"type":"table"}}):_vm._e(),(_vm.data_loaded)?_c('v-card',{staticClass:"mb-10",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold"},[(_vm.$store.state.user_menu_dashboard.is_admin != 1)?_c('h4',[_vm._v("المنتجات")]):_c('h4',[_vm._v("منتجات ("+_vm._s(_vm.$route.query.store_name)+")")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"بحث"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(this.$store.state.user_menu_dashboard.is_admin != 1)?_c('v-btn',{attrs:{"to":"/Product","color":"primary"}},[_vm._v("اضافة منتج")]):_vm._e()],1),_c('v-data-table',{attrs:{"footer-props":_vm.$store.state.footer_props,"items":_vm.products,"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category[0].name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.status == 1)?_c('v-chip',{staticClass:"px-2",attrs:{"x-small":"","color":"success"}},[_vm._v(" متوفر")]):(item.status == 2)?_c('v-chip',{staticClass:"px-2",attrs:{"x-small":"","color":"error"}},[_vm._v(" غير متوفر")]):_c('v-chip',{staticClass:"px-2",attrs:{"x-small":"","color":"grey lighten-2"}},[_vm._v(" مخفي")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.user_menu_dashboard.is_admin == 1)?_c('v-btn',{attrs:{"to":{
              name: 'product',
              query: { category_name: item.category },
              params: { id: item.id },
            },"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1):_vm._e(),(_vm.$store.state.user_menu_dashboard.is_admin != 1)?_c('v-btn',{attrs:{"to":{
              name: 'product',
              params: { id: item.id },
            },"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1):_vm._e(),(_vm.$store.state.user_menu_dashboard.is_admin != 1)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.open_delete_dialog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1):_vm._e()]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(" حذف المنتج ؟ ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"depressed":"","color":"error"},on:{"click":function($event){return _vm.delete_product()}}},[_vm._v("حذف")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v("الغاء")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","left":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-icon',{staticClass:"ms-3",attrs:{"color":"white"}},[_vm._v("check_circle")])]},proxy:true}]),model:{value:(_vm.snackbar_success),callback:function ($$v) {_vm.snackbar_success=$$v},expression:"snackbar_success"}},[_c('p',[_vm._v("تم حذف المنتج")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }